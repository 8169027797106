export default class OfficeCarousel {
  constructor(element) {
    this.el = element;
    this.swiper = this.el.querySelector(".swiper");
    this.config = this.swiper?.dataset.carouselPagination ?? "bullets";
    this.init();
  }

  init() {
    let config = {};
    const pagination = [];
    const names = document.querySelectorAll('[data-pagination-name]');

    names.forEach(slide => {
      pagination.push(slide.dataset.paginationName)
    });

    config = {
      spaceBetween: 30,
      slidesPerView: 1,
      loop: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
      },
      pagination: {
        el: this.el.querySelector(".swiper-pagination"),
        clickable: true,
        bulletClass: 'pagination-item',
        bulletActiveClass: "pagination-item--active",
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (pagination[index]) + '</span>';
        },
      },
      navigation: {
        nextEl: this.el.querySelector(".swiper-button-next"),
        prevEl: this.el.querySelector(".swiper-button-prev"),
      }
    };
    
    const swiper = new Swiper(this.swiper, config);

    let carouselItems = this.el.querySelectorAll(".carousel-item");

      carouselItems.forEach(carouselItem => {
        let content = carouselItem.querySelector(".item-content");

        carouselItem.addEventListener("mouseleave", (e) => {
          content.style.top = "calc(100% - 140px)";
        });

        carouselItem.addEventListener("mouseenter", (e) => {
          content.style.top = (carouselItem.clientHeight - content.clientHeight) + "px";
        });

        //If hover is unavailable, display content item
        if (window.matchMedia("(hover: none)").matches) {
          content.style.top = "inherit";
          content.style.bottom = "0px";
        }

      });
  }
}
