import Player from "@vimeo/player";
export default class PromoVideoModule {
  constructor(element) {
    this.el = element;
    this.playButton = this.el.querySelector(".play-button");
    this.videoId = this.el.dataset.videoid;
    this.isLooped = this.el.dataset.looped;
    this.body = document.querySelector("body");
    this.videoContainer = this.el.querySelector(".video-container");
    this.loadVideo = this.loadVideo.bind(this);
    this.consentButton = this.el.querySelector(".consent-button");

    this.allowVimeo = false;
    this.checkForCookie = this.checkForCookie.bind(this);

    this.init();
  }

  init() {
    window.addEventListener("CookiebotOnAccept", (e) => {
      let newGivenConsent = document.cookie;
      if (newGivenConsent.includes("statistics:true") && newGivenConsent.includes("necessary:true")) {
        this.allowVimeo = true;
        this.playButton.style.display = "flex";
        this.consentButton.style.display = "none";
        if (this.playButton) {
          this.playButton.addEventListener("click", (e) => {
            this.loadVideo(this.videoId);
          });
        }
      }
      this.checkForCookie();
    });

    let givenConsents = document.cookie;

    if (givenConsents) {
      if (givenConsents.includes("statistics:true") && givenConsents.includes("necessary:true")) {
        this.allowVimeo = true;
        this.playButton.style.display = "flex";
        this.consentButton.style.display = "none";
        if (this.playButton) {
          this.playButton.addEventListener("click", (e) => {
            this.loadVideo(this.videoId);
          });
        }
      } else {
        this.playButton.style.display = "none";
        this.consentButton.style.display = "flex";
      }
    } else {
      //if i cant get cookies
      this.playButton.style.display = "none";
      this.consentButton.style.display = "flex";
    }

    this.checkForCookie();

    // if (this.playButton) {
    //   this.playButton.addEventListener("click", (e) => {
    //     this.loadVideo(this.videoId);
    //   });
    // }
  }

  checkForCookie() {
    if (this.playButton && this.allowVimeo) {
      this.playButton.addEventListener("click", (e) => {
        this.openPopup();
      });
    }
  }

  loadVideo(videoid) {
    var videoContainer = "promo-video-" + videoid;
    this.videoContainer.innerHTML = `<div class="video-player-container" id="${videoContainer}" data-vimeo-id="${videoid}" data-vimeo-defer="true"></div>`;

    var options = {
      loop: this.isLooped == "True" ? 1 : 0,
      transparent: 0,
      background: 0,
      muted: 0,
      autoplay: 0,
    };

    this.player = new Player(videoContainer, options);
    console.log("this.player: ", this.player);

    this.player
      .enableTextTrack("da")
      .then(function (track) {
        track.language = da;
        track.kind = "subtitles";
      })
      .catch(function (error) {
        switch (error.name) {
          case "InvalidTrackLanguageError":
            // no track was available with the specified language
            break;

          case "InvalidTrackError":
            // no track was available with the specified language and kind
            break;

          default:
            // some other error occurred
            break;
        }
      });

    this.player.play();
  }
}
