import 'regenerator-runtime'
import './index.css'
import {EventBus, EventTypes} from './consts/const-event-types'

/*
To add new components, import them here and add them to the 'components'-array below.
Add an appropriate selectorName for use in DOM (fx: <div data-component="header"></div>):
*/
import App from './components/app'
import Header from './components/header/header'
import ExampleComponent from './components/example-component/example-component'
import UserItem from './components/user-item/user-item'
import Carousel from './components/carousel/carousel'
import HeaderCarousel from './components/header-carousel/header-carousel'
import Navigation from './components/navigation/navigation'
import Slider from './components/Modules/Slider/slider'
import Faq from './components/Modules/Faq/faq'
import Insights from './components/Modules/insights/insights'
import OfficeCarousel from './components/offices-carousel/office-carousel'
import VimeoModule from './components/vimeo-module/vimeo-module'
import PromoVideoModule from './components/promo-video-module/promo-vimeo-module'
import TestimonialModule from './components/testimonial-module/testimonial-module'
import InlineIconsModule from './components/inline-icons-module/inline-icons.module'
import OurValuesModule from './components/our-values-module/our-values-module'
import OfficeListModule from './components/office-list-module/office-list.module'
import ContactFormModule from './components/contact-form-module/contact-form-module'
import ScrollToForm from './components/Modules/Forms/scroll-to-form'

//Import lazysizes as early as possible.
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


//Set lazysizes configuration.
lazySizes.cfg.lazyClass = 'lazyload';
lazySizes.cfg.loadedClass = 'loaded';
lazySizes.cfg.customMedia = {
  '--small': '(max-width: 640px)',
  '--medium': '(max-width: 1024px)',
  '--large': '(min-width:1440px)',
  '--xlarge': '(min-width:1920px)'
}

const components = [
  {Class: App, selectorName: 'app'},
  {Class: Header, selectorName: 'header'},
  {Class: ExampleComponent, selectorName: 'example-component'},
  {Class: UserItem, selectorName: 'user-item'},
  {Class: Carousel, selectorName: 'carousel'},
  {Class: Navigation, selectorName: 'navigation'},
  {Class: HeaderCarousel, selectorName: 'header-carousel'},
  {Class: Slider, selectorName: 'slider'},
  {Class: Faq, selectorName: 'faqs'},
  {Class: Insights, selectorName: 'insights'},
  {Class: OfficeCarousel, selectorName: 'office-carousel'},
  {Class: VimeoModule, selectorName:'vimeo-module'},
  {Class: PromoVideoModule, selectorName:'promo-vimeo-module'},
  {Class: TestimonialModule, selectorName: 'testimonial-module'},
  {Class: InlineIconsModule, selectorName: 'inline-icons-module'},
  {Class: OurValuesModule, selectorName: 'our-values-module'},
  {Class: OfficeListModule, selectorName: 'office-list-module'},
  {Class: ContactFormModule, selectorName: 'contact-form-module'},
  {Class: ScrollToForm, selectorName: 'scroll-to-form'}
]

/*
* ComponentInstantiator traverses the DOM-tree and looks for [data-component='] attributes.
*/
class ComponentInstantiator {
  constructor() {
    this.createComponents()
    this.dispatchReady()
    return this
  }

  createComponents() {
    this.componentsInstantiated = {}

    components.map(component => {
      const nodes = []
      // select all DOM-nodes with the data-component attribute:
      const nodeItems = [...document.querySelectorAll("[data-component='" + component.selectorName + "']")]

      nodeItems.map(nodeItem => {
        // instantiate component with nodeItem as param:
        nodes.push(new component.Class(nodeItem))
      })

      // add to componentsList object:
      if (nodes.length) this.componentsInstantiated[component.selectorName] = nodes
    })
  }

  dispatchReady() {
    const event = new CustomEvent(EventTypes.ALL_COMPONENTS_READY)
    EventBus.dispatchEvent(event)
  }

  getComponents(selectorName) {
    return selectorName ? this.componentsInstantiated[selectorName] : this.componentsInstantiated
  }
}

/* Make your App accessible via window.App */
window.App = new ComponentInstantiator()

/* Access components list */
// console.log('components on this page', window.App.getComponents())
