export default class TestimonialModule {
    constructor(element) {
      this.el = element;
      this.swiper = this.el.querySelector(".swiper");
      this.slides = Array.from(this.el.querySelectorAll(".swiper-slide"));
      this.elementIsInViewport = false;   
      this.isElementInViewport = this.isElementInViewport.bind(this);
      this.init();
    }
  
    init() {
        let config = {
            speed: 400,
            spaceBetween: 24,
            slidesPerView: 1,
            slidesPerGroup: 1,
            loop: true,
            initialSlide: 0,
            breakpoints: {
              768: {
                slidesPerGroup: 1,
                slidesPerView: 2,
                spaceBetween: 25,
              },
              1200: {
                slidesPerGroup: 1,
                slidesPerView: 3,
                spaceBetween: 25,
              },
            },
            pagination: {
              el: '.swiper-pagination',
              dynamicBullets: true,
              clickable: true,
              renderBullet: function(index, className) {
                  return '<span class="' + className + ' swiper-pagination-bullet--svg-animation"><svg width="26" height="26" viewBox="0 0 28 28"><circle class="svg__circle" cx="14" cy="14" r="12" fill="none" stroke-width="2"></circle><circle class="svg__circle-inner" cx="14" cy="14" r="6" stroke-width="3"></circle></svg></span>';
              },
          },
            navigation: {
              nextEl: this.el.querySelector(".swiper-button-next"),
              prevEl: this.el.querySelector(".swiper-button-prev"),
            },
          };
          

          const swiper = new Swiper(this.swiper, config);

            
                window.addEventListener("scroll", () => {
                    if (!this.elementIsInViewport && this.isElementInViewport(this.swiper)) {
                        this.elementIsInViewport = true;
                        window.removeEventListener("scroll", this.scrollListener);
                        if (window.innerWidth > 767) { 
                          this.scrollFunction();
                         } else {
                          this.startSwiperAutorun();
                         }
                    }
                  
                  });
                  if (this.slides.length <= 3) {
                    this.el.querySelector(".swiper-pagination").classList.add("hide-pagination");
                  }
        
                  this.scrollListener = () => {
                    if (!this.elementIsInViewport && this.isElementInViewport(this.swiper)) {
                        this.elementIsInViewport = true;
                        window.removeEventListener("scroll", this.scrollListener);
                    }
                  };

                  if (this.isElementInViewport(this.swiper)) {
                    this.scrollFunction();
                  }
    };

    isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }
      scrollFunction() {
                this.allSlides = this.el.querySelectorAll(".swiper-slide");
                this.allSlides.forEach((slide, index) => {
                  slide.style.transitionDelay = `${index * 0.2}s`;
                  slide.classList.add("animate-in");
                });
                this.startSwiperAutorun();
    }
    startSwiperAutorun() {
        const swiper = this.swiper.swiper;
        swiper.autoplay.start();

    }
  }
