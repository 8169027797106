import Player from "@vimeo/player";

import { getCookie } from "../../services/service-cookies";
export default class VimeoModule {
  constructor(element) {
    this.el = element;
    this.playButton = this.el.querySelector(".play-button");
    this.consentButton = this.el.querySelector(".consent-button");
    this.videoId = this.el.dataset.videoid;
    this.isLooped = this.el.dataset.looped;
    this.popUp = this.el.nextElementSibling;
    this.closeButton = this.popUp.querySelector(".close-button");
    this.body = document.querySelector("body");
    this.videoContainer = this.el.querySelector("video-container");

    this.menuButton = document.querySelector(".menu__toggler");
    this.allowVimeo = false;

    this.checkForCookie = this.checkForCookie.bind(this);

    this.openPopup = this.openPopup.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
    this.closePopUp = this.closePopUp.bind(this);

    this.init();
  }

  init() {
    window.addEventListener("CookiebotOnAccept", (e) => {
      let newGivenConsent = document.cookie;
      if (newGivenConsent.includes("statistics:true") && newGivenConsent.includes("necessary:true")) {
        this.allowVimeo = true;
        this.playButton.style.display = "flex";
        this.consentButton.style.display = "none";
        this.loadVideo(this.videoId);
      }
      this.checkForCookie();
    });

    let givenConsents = document.cookie;

    if (givenConsents) {
      if (givenConsents.includes("statistics:true") && givenConsents.includes("necessary:true")) {
        this.allowVimeo = true;
        this.playButton.style.display = "flex";
        this.consentButton.style.display = "none";
        this.loadVideo(this.videoId);
      } else {
        this.playButton.style.display = "none";
        this.consentButton.style.display = "flex";
      }
    } else {
      //if i cant get cookies
      this.playButton.style.display = "none";
      this.consentButton.style.display = "flex";
    }

    this.checkForCookie();

    if (this.closeButton) {
      this.closeButton.addEventListener("click", (e) => {
        this.closePopUp();
      });
    }
  }

  checkForCookie() {
    if (this.playButton && this.allowVimeo) {
      this.playButton.addEventListener("click", (e) => {
        this.openPopup();
      });
    }
  }

  openPopup() {
    this.el.classList.add("activated");
    this.popUp.classList.add("active");

    this.menuButton.style.display = "none";

    if (!this.player) {
      setTimeout(() => {
        document.documentElement.style.getPropertyValue("--scroll-y");
        this.body.style.top = `-${scrollY}px`;
        this.body.style.position = "fixed";
      }, 500);
    } else {
      this.player.play();
      setTimeout(() => {
        document.documentElement.style.getPropertyValue("--scroll-y");
        this.body.style.top = `-${scrollY}px`;
        this.body.style.position = "fixed";
      }, 500);
    }
  }

  loadVideo(videoid) {
    var videoContainer = "video-" + videoid;
    var options = {
      loop: this.isLooped == "True" ? 1 : 0,
      transparent: 0,
      background: 0,
      muted: 0,
      autoplay: 0,
    };

    this.player = new Player(videoContainer, options);

    this.player
      .enableTextTrack("da")
      .then(function (track) {
        track.language = da;
        track.kind = "subtitles";
      })
      .catch(function (error) {
        switch (error.name) {
          case "InvalidTrackLanguageError":
            // no track was available with the specified language
            break;

          case "InvalidTrackError":
            // no track was available with the specified language and kind
            break;

          default:
            // some other error occurred
            break;
        }
      });
  }

  closePopUp() {
    this.el.classList.remove("activated");
    if (this.allowVimeo) {
      this.player.pause();
    }
    const scrollY = this.body.style.top;
    this.body.style.position = "";
    this.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    this.popUp.classList.remove("active");
    this.menuButton.style.display = "flex";
  }
}
