
export default class Slider {
  constructor(element) {
    this.el = element;
    this.slider = this.el.querySelector(".swiper");
    this.init();
  }

  init() {
    const swiper = new Swiper(this.slider, {
      slidesPerView: 1,
      pagination: {
        el: this.el.querySelector(".swiper-pagination"),
        clickable: true,
      },
      navigation: {
        nextEl: this.el.querySelector(".swiper-button-next"),
        prevEl: this.el.querySelector(".swiper-button-prev"),
      },
    });
  };
}