export default class InlineIconsModule {
    constructor(element) {
        this.el = element;
        this.section = this.el;
        this.icons = Array.from(this.el.querySelectorAll('.icon-item-wrapper > div'));
        this.elementIsInViewport = false;   
        this.hasTitle = this.el.querySelector('.heading') ? true : false;
        this.isElementInViewport = this.isElementInViewport.bind(this);
        this.init();
    }

    init() {
        window.addEventListener("scroll", () => {
            if (!this.elementIsInViewport && this.isElementInViewport(this.el)) {
                this.elementIsInViewport = true;
                window.removeEventListener("scroll", this.scrollListener);
                  this.scrollFunction();
            }
          
          });

          this.scrollListener = () => {
            if (!this.elementIsInViewport && this.isElementInViewport(this.el)) {
                this.elementIsInViewport = true;
                window.removeEventListener("scroll", this.scrollListener);
            }
          };
          if (this.isElementInViewport(this.el)) {
            this.elementIsInViewport = true;
            this.scrollFunction();
          }
    }
    scrollFunction() {

            this.icons.forEach((icon, index) => {
              icon.style.transition = `all 0.5s ${index * 0.1}s`;
              icon.classList.add('animate-in');
            });
    }
    isElementInViewport(el) {
      let offset = 750;
      if (this.hasTitle) {
        offset = 250;
      }
        const rect = el.getBoundingClientRect();
        return (
          rect.top <= offset &&
          rect.left >= 0 &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      }
    
}