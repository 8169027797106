export default class OfficeListModule {
  constructor(element) {
    this.el = element
    this.countrySelect = this.el.querySelector("select");
    this.filterList = this.el.querySelector(".filter-list");
    this.filterListItems = this.filterList.querySelectorAll("[data-filter]");
    this.countryPins = this.el.querySelectorAll(".pin");
    this.defaultValue;
    this.selectedCountry;
    this.changeFilters = this.changeFilters.bind(this);

    this.init()
  }

  init() {

    if (this.countrySelect) {
        this.defaultValue = this.countrySelect.value;
        this.countrySelect.addEventListener("change", (e) => {
            this.changeFilters(e.target.value);
            
        });
        this.changeFilters(this.defaultValue);
        this.selectedCountry = this.defaultValue;
    }
    if (this.countryPins) {
        this.countryPins.forEach(pin => {
            pin.addEventListener("click", (e) => {
                this.changeFilters(pin.dataset.pin);

            });
        });
    
    }
    

  }
  changeFilters(id) {
    if (id != this.selectedCountry) { 
        this.filterListItems.forEach(filter => {
            if (filter.dataset.filter == id) {
                filter.classList.add("active");
            } else {
                filter.classList.remove("active");
            }
        });
        this.countryPins.forEach(pin => {
            if (pin.dataset.pin == id) {
                pin.classList.add("active");
            } else {
                pin.classList.remove("active");
            }
        });
        this.selectedCountry = id;
        this.countrySelect.value = id;
    }
  }


}
