const BASE_URL = '/umbraco/api'
export const ENDPOINT_IS_AUTHENTICATED =     `${BASE_URL}/account/IsAuthenticated`

export const ENDPOINT_GH_REPOS =             `https://api.github.com/repos/javascript-tutorial/en.javascript.info/commits`
export const ENDPOINT_SMK_ART =              `https://api.smk.dk/api/v1/art/search/?keys=hest&offset=0&rows=5`
export const ENDPOINT_MATRIKEL =             `https://dawa.aws.dk/jordstykker/2000174/1209?format=geojson&srid=25832&noformat&cache=no-cache`


const headersDefault = Object.freeze({
  'Content-Type': 'application/json'
})


export const getEndpoint = (ENDPOINT, POST = false, payload) => {
  const options = {
    method: POST ? 'POST' : 'GET',
    headers: {...{}, ...headersDefault},
    ...POST && payload && {body: JSON.stringify({...{}, ...payload})}
  }

  return fetch(ENDPOINT, options)
    .then(res => res)
    .then(res => res.json())
    .catch(error => console.log(error))
}
