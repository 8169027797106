export default class ExampleComponent {
  constructor(element) {
    this.el = element
    this.init()
  }

  init() {
    // console.log('Example-component initiated with this element', this.el)
  }
}
