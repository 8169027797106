export default class Navigation {
  constructor(element) {
    this.el = element;
    this.main = document.querySelector("main");
    this.header = document.querySelector("header");
    this.toggleMenu = this.el.querySelector(".menu-toggle");
    this.navigationParent = Array.from(this.el.querySelectorAll(".nav-button"));
    this.dynamicBackground = this.el.querySelector(".dynamic-background");
    this.currentPage = this.el.querySelector(".current");
    this.toggleChildAnimation = this.toggleChildAnimation.bind(this);
    this.toggleSubNavigation = this.toggleSubNavigation.bind(this);
    this.scrollFunction = this.scrollFunction.bind(this);
    this.serviceButtons = this.el.querySelectorAll(".service a");
    this.init();
  }

  init() {
    if (this.toggleMenu) {
      this.toggleMenu.addEventListener("click", () => {
        this.openCloseMenuMobile();
      });
    }
    window.addEventListener("scroll", this.scrollFunction);

    if (this.navigationParent.length === 0) return;
    this.navigationParent.forEach((parent) => {
      parent.addEventListener("click", (e) => {
        e.preventDefault();
        this.toggleSubNavigation(parent);
      });
    });

    if (this.main) {
      this.main.addEventListener("click", () => {
        if (this.el.classList.contains("open")) {
          this.el.classList.remove("open");
          this.navigationParent.forEach((nav) => {
            if (!nav.classList.contains("active")) return;
            nav.classList.remove("active");
            const parentListItem = nav.closest("li");
            const parentChildList = parentListItem.querySelector(".sub_links");
            parentChildList.classList.remove("active");
            this.toggleChildAnimation(parentChildList);
          });
          this.calculateHeight();
        }
      });
    }
    if (this.header) {
      this.header.addEventListener("click", (e) => {
        if (this.el.classList.contains("open")) {
          this.el.classList.remove("open");
          this.navigationParent.forEach((nav) => {
            if (!nav.classList.contains("active")) return;
            nav.classList.remove("active");
            const parentListItem = nav.closest("li");
            const parentChildList = parentListItem.querySelector(".sub_links");
            parentChildList.classList.remove("active");
            this.toggleChildAnimation(parentChildList);
          });
          this.calculateHeight();
        }
      });
    }
    if (this.serviceButtons) {
      this.serviceButtons.forEach((button) => {
        button.addEventListener("click", () => {
          if (window.innerWidth < 1200) {
            this.openCloseMenuMobile();
            
          } else {
            if (this.el.classList.contains("open")) {
              this.el.classList.remove("open");
              this.navigationParent.forEach((nav) => {
                if (!nav.classList.contains("active")) return;
                nav.classList.remove("active");
                const parentListItem = nav.closest("li");
                const parentChildList = parentListItem.querySelector(".sub_links");
                parentChildList.classList.remove("active");
                this.toggleChildAnimation(parentChildList);
              });
              this.calculateHeight();
            }
          }
          
        })
      });
    }
    
    // check if there is initial scroll
    this.scrollFunction();

    if (this.currentPage) {
      const currentSubWrapper = this.currentPage.closest(".sub-wrapper");
      if (!currentSubWrapper) return;
      const currentParent = currentSubWrapper.closest("li")
      if (currentParent) {
        currentParent.classList.add("current");
      }
    }
  }

  toggleSubNavigation(parent) {
    const parentLi = parent.closest("li");
    const parentChildList = parentLi.querySelector(".sub_links");
    const isOpen = parent.classList.toggle("active");
    parentChildList.classList.toggle("active", isOpen);
    this.el.classList.toggle("open", isOpen);
    let delay = 0;
    if (window.innerWidth < 1200) {
      delay = 0;
      const outer = parentLi.querySelector(".sub-wrapper");
      const inner = outer.querySelector(".sub_links__list");
      const height = inner.offsetHeight;
      outer.style.height = isOpen ? `${height}px` : "0";
    } else {
      delay = 300;
    }
    this.closeOtherSubNavigations(parent);
    setTimeout(() => {
      this.toggleChildAnimation(parentChildList);
      this.calculateHeight();
    }, delay)
  }

  closeOtherSubNavigations(currentParent) {
    this.navigationParent.forEach((nav) => {
      if (nav !== currentParent && nav.classList.contains("active")) {
        const parentListItem = nav.closest("li");
        const parentChildList = parentListItem.querySelector(".sub_links");
        nav.classList.remove("active");
        parentChildList.classList.remove("active");
        this.toggleChildAnimation(parentChildList);
      }
    });
  }

  toggleChildAnimation(parentChildList) {
    const parentChildListItems = Array.from(parentChildList.querySelectorAll("li, p"));
    parentChildListItems.forEach((child) => {
      child.style.animation ? child.style.animation = "" : child.style.animation = `navLinkFade 0.5s ease forwards`;
    });
  }

  calculateHeight() {
    if (window.innerWidth < 1200) return;
    let height;
    if (!this.el.classList.contains("open")) {
      height = 0;
      this.dynamicBackground.style.height = `${height}px`;
    } else {
      
      height = this.el.offsetHeight + this.el.querySelector(".sub_links.active").offsetHeight - 32;
      this.dynamicBackground.style.height = `${height}px`;
    }
  }

  openCloseMenuMobile() {
    this.el.classList.toggle("menu-open");
  }

  scrollFunction() {
    const scrolled = document.body.scrollTop > 80 || document.documentElement.scrollTop > 80;
    this.el.classList.toggle("scrolled", scrolled);
  }    
}
