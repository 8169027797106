export default class HeaderCarousel {
  constructor(element) {
    this.el = element;
    this.slider = this.el.querySelector(".splide");

    //this.generateSlider = this.generateSlider.bind(this);
    this.init();
  }

  init() {
    this.slider = new Splide('.splide', {
      autoplay: true,
      interval: 11000,
      pauseOnHover: false,
      speed: 800,
      arrows: false,
      drag: false,
      snap: true,
      type: 'loop',
      cover: false,
      pagination: true,
      rewind: true,
    }).mount();
  };
}