export default class Carousel {
  constructor(element) {
    this.el = element;
    this.swiper = this.el.querySelector(".swiper");
    this.slides = Array.from(this.el.querySelectorAll(".swiper-slide"));
    this.elementIsInViewport = false;   
    this.isElementInViewport = this.isElementInViewport.bind(this);
    this.config = this.swiper?.dataset.carouselPagination ?? "bullets";
    this.carouselType = this.el.dataset.carouselType ?? "other";
    this.init();
  }

  init() {

    let config = {};
    const pagination = [];
    const names = document.querySelectorAll('[data-pagination-name]');

    names.forEach(slide => {
      pagination.push(slide.dataset.paginationName)
    });

    if (this.config === "custom") {
      config = {
        spaceBetween: 30,
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        speed: 400,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
        pagination: {
          el: this.el.querySelector(".swiper-pagination"),
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 1,
          bulletClass: 'pagination-item',
          bulletActiveClass: "pagination-item--active",
          renderBullet: function(index, className) {
            return '<span class="' + className + ' swiper-pagination-bullet--svg-animation"><svg width="26" height="26" viewBox="0 0 28 28"><circle class="svg__circle" cx="14" cy="14" r="12" fill="none" stroke-width="2"></circle><circle class="svg__circle-inner" cx="14" cy="14" r="6" stroke-width="3"></circle></svg></span>';
        },
        }
      };
    } 
    else {
      config = {
        speed: 400,
        spaceBetween: 24,
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        initialSlide: 0,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
        },
        pagination: {
          el: this.el.querySelector(".swiper-pagination"),
          dynamicBullets: true,
          clickable: true,
          dynamicMainBullets: 1,
          renderBullet: function(index, className) {
            return '<span class="' + className + ' swiper-pagination-bullet--svg-animation"><svg width="26" height="26" viewBox="0 0 28 28"><circle class="svg__circle" cx="14" cy="14" r="12" fill="none" stroke-width="2"></circle><circle class="svg__circle-inner" cx="14" cy="14" r="6" stroke-width="3"></circle></svg></span>';
        },
        }
      };
    }
    const swiper = new Swiper(this.swiper, config);
    window.addEventListener("scroll", () => {
      if (!this.elementIsInViewport && this.isElementInViewport(this.swiper)) {
          this.elementIsInViewport = true;
          window.removeEventListener("scroll", this.scrollListener);
          if (window.innerWidth > 767) { 
            this.scrollFunction();
           } else {
            this.startSwiperAutorun();
           }
      }
    
    });
    if (this.slides.length <= 3) {
      this.el.querySelector(".swiper-pagination").classList.add("hide-pagination");
      this.el.classList.add("cursor-default");
    }

    this.scrollListener = () => {
      if (!this.elementIsInViewport && this.isElementInViewport(this.swiper)) {
          this.elementIsInViewport = true;
          window.removeEventListener("scroll", this.scrollListener);
      }
    };

    if (this.isElementInViewport(this.swiper)) {
      this.scrollFunction();
    }
    
  }
  isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  scrollFunction() {
            this.allSlides = this.el.querySelectorAll(".swiper-slide");
            this.allSlides.forEach((slide, index) => {
              slide.style.transitionDelay = `${index * 0.2}s`;
              slide.classList.add("animate-in");
            });
            this.startSwiperAutorun();
}
startSwiperAutorun() {
    const swiper = this.swiper.swiper;
    swiper.autoplay.start();

}
}
