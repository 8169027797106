export default class Faq {
  constructor(element) {
    this.el = element;
    this.faq = this.el.querySelectorAll(".faq-block-list__item");
    this.bodyWrapperAll = document.querySelectorAll(".faq-block__list__item__body");
    this.init();
  }

  init() {
    if(this.faq) {
      // Initial state 
      this.bodyWrapperAll.forEach((bodyWrapper) => {
        bodyWrapper.style.maxHeight = "0px";
      });

      this.faq.forEach((faq) => {
        // Toggle is-active class and max-height on FAQ body
        faq.addEventListener("click", () => {
          faq.classList.toggle("is-active");
          const bodyWrapper = faq.querySelector(".faq-block__list__item__body");
          if(faq.classList.contains("is-active")) {
            bodyWrapper.style.maxHeight = bodyWrapper.scrollHeight + "px";
          } else {
            bodyWrapper.style.maxHeight = "0px";
          }
        }
        );
      });
    }
  };
}