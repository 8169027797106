export default class UserItem {
  constructor(element, data) {
    this.el = element
    this.data = data

    this.onClick = this.onClick.bind(this)

    this.init()
  }

  init() {
    this.el.addEventListener('click', this.onClick)
  }

  onClick() {
    console.log('UserItem clicked', this.el.textContent, this.data)
  }

  static template(data) {
    const {name} = data
    return `
      <li class="user-item" data-component="user-item">${name}</li>
    `
  }
}
