import Vimeo from '@vimeo/player'
export default class Header {
  constructor(element) {
    this.el = element
    this.vimeo = this.el.querySelector('.vimeo')
    this.startVimeo = this.startVimeo.bind(this)
    this.getViewportSize = this.getViewportSize.bind(this)
    this.setOrientation = this.setOrientation.bind(this)
    this.currentOrientation = null;
    this.init()
  }

  init() {
    this.setOrientation();
    if (this.vimeo) {
      this.vimeoLandscapeId = this.vimeo.dataset.vimeolandscapeid
      this.vimeoPortraitId = this.vimeo.dataset.vimeoportraitid
      this.startVimeo(this.currentOrientation === "landscape" ? this.vimeoLandscapeId : this.vimeoPortraitId);
      window.addEventListener("resize", this.getViewportSize);
    }
  
    

  }

  startVimeo(id) {
    this.player = new Vimeo(this.vimeo, {
      id: id,
      controls: false,
      loop: true,
      autoplay: true,
      background: true,
      responsive: true,
    });
    this.player.setVolume(0);

  }
  getViewportSize() {
    // if media query matches > 1024px
    if (window.innerWidth > 1024 && this.currentOrientation !== "landscape") {
      this.player.destroy();
      this.startVimeo(this.vimeoLandscapeId);
      this.currentOrientation = "landscape";
    } else if (window.innerWidth <= 1024 && this.currentOrientation !== "portrait") {
      this.player.destroy();
      this.startVimeo(this.vimeoPortraitId);
      this.currentOrientation = "portrait";
    }
    
  }
  setOrientation() {
    if (window.innerWidth > window.innerHeight) {
      this.currentOrientation = "landscape";
    } else {
      this.currentOrientation = "portrait";
    }
  }
}
