export default class InlineIconsModule {
    constructor(element) {
        this.el = element;
        this.section = this.el;
        this.iconWrapper = this.el.querySelector('.icon-item-wrapper');
        this.icons = Array.from(this.iconWrapper.children);
        this.isInView = false;
        this.animationTriggered = false; 
        this.init();
    }

    init() {
        const icon1 = this.icons[0];
        icon1.classList.add("fade-in");
        window.addEventListener("scroll", () => {
            if (window.innerWidth > 1023) {
                this.handleScroll.bind(this)();
            }
        });
    }

    handleScroll() {
        if (window.innerWidth > 1023) {
            const isSectionInView = this.isSectionAtTop();
        if (!this.isInView && isSectionInView && !this.animationTriggered) { 
            this.isInView = true;
            this.animateIcons();
            this.animationTriggered = true; 
        } else if (this.isInView && !isSectionInView) {
            this.isInView = false;
        }
        }
    }

    isSectionAtTop() {
        const rect = this.section.getBoundingClientRect();
        let containerOffset = window.innerHeight * 0.5;
        if (window.innerWidth <= 1024) {
            containerOffset = window.innerHeight * 0.3; 
        }
        return rect.top <= containerOffset; 
    }

    animateIcons() {
        console.log("Start animation progress");
        const sectionTop = this.section.offsetTop;
        const sectionHeight = this.section.offsetHeight;
        const windowHeight = window.innerHeight;
        let containerOffset = 150; // how much offset we want the icons to have from the top of the container initially
        window.addEventListener("scroll", () => {
            if (window.innerWidth > 1024) {
                const scrollTop = window.scrollY;
            const sectionBottom = sectionTop + sectionHeight;
            const bottomOffset = sectionBottom - windowHeight;
            let progress;
                progress = Math.max(Math.min(Math.round((scrollTop - bottomOffset + 500) / windowHeight * 100), 100), 0);
                progress = Math.min(progress, 75);
            this.iconWrapper.style.transform = `translateY(calc(-${progress}% + ${containerOffset}px))`;
    
            const icon1 = this.icons[0];
            const icon2 = this.icons[1];
            const icon3 = this.icons[2];
            if (progress <= 27 && progress < 50) {
                icon1.classList.add("fade-in");
                icon2.classList.remove("fade-in");
                icon3.classList.remove("fade-in");
            } else if (progress >= 27 && progress < 63) {
                icon1.classList.remove("fade-in");
                icon2.classList.add("fade-in");
                icon3.classList.remove("fade-in");
            } else if (progress >= 63) {
                icon1.classList.remove("fade-in");
                icon2.classList.remove("fade-in");
                icon3.classList.add("fade-in");
            } else {
                icon1.classList.remove("fade-in");
                icon2.classList.remove("fade-in");
                icon3.classList.remove("fade-in");
            }
                
            }  else {
                console.log("Animation progress stopped");
                this.iconWrapper.style.transform = `translateY(0)`;
                return;
            }
            
        });
    }
}
