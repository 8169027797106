export default class ContactFormModule {
  constructor(element) {
    this.el = element;
    this.initialForm = this.el.querySelector('.contact-form');
    this.initialFormContainer = this.el.querySelector(".first-form");
    this.initialFormId = this.initialForm.id;
    this.secondFormContainer = this.el.querySelector(".second-form");
    this.secondForm = this.el.querySelector('.second-form section');
    this.thankYouMessage = this.el.querySelector('.thank-you');
    if (this.secondForm) {
        this.secondFormId = this.secondForm.id;
    }
    this.sessionStorage = sessionStorage;
    this.formCount = this.el.dataset.formcount;

    this.initialFormEvents = this.initialFormEvents.bind(this);
    this.init();
  }

    init() {
        if (this.sessionStorage.getItem('formId') === this.initialFormId) {
            this.initialFormContainer.style.display = "none";
            this.secondFormContainer.style.display = "block";
            if (this.sessionStorage.getItem('ScrollToForm') === 'true') {
                this.secondFormContainer.scrollIntoView({block: 'center'});
                this.sessionStorage.removeItem('ScrollToForm');
                this.sessionStorage.removeItem('formId');
            }
            if (this.formCount === "1") {
                this.thankYouMessage.style.display = "block";
                this.thankYouMessage.scrollIntoView({block: 'center'});

             }
        }
        if (this.sessionStorage.getItem('formId2') && !this.sessionStorage.getItem('showReceipt')) {
                this.secondForm.scrollIntoView({block: 'center'});
                this.sessionStorage.removeItem('formId2');
                
            
        }
        if (this.sessionStorage.getItem('showReceipt')) {
            this.secondForm.style.display = "none";
            this.secondFormContainer.style.display = "block";
            this.initialFormContainer.style.display = "none";
            this.thankYouMessage.style.display = "block";
            if (this.sessionStorage.getItem('ScrollToForm2') === 'true') {
                this.thankYouMessage.scrollIntoView({block: 'center'});
                this.sessionStorage.removeItem('ScrollToForm2');
                this.sessionStorage.removeItem('showReceipt');
            }
        }
        this.initialFormEvents()

    }

    initialFormEvents() {
        const submit = this.initialForm.querySelector('input[type="submit"]');
        if (submit) {
            submit.addEventListener('click', (e) => {
                setTimeout(() => { 
                    const activeErrors = this.initialForm.querySelectorAll('.field-validation-error');
                    if (activeErrors.length) return;
                    sessionStorage.setItem('formId', this.initialFormId);
                    sessionStorage.setItem('ScrollToForm', 'true');
                }, 50);
            });
        }
        if (!this.secondForm) return;
        const submitSecondForm = this.secondForm.querySelector('input[type="submit"]');
        
        if (submitSecondForm) {
            submitSecondForm.addEventListener('click', (e) => {
                setTimeout(() => {
                    const activeErrors = this.secondForm.querySelectorAll('.field-validation-error');
                    if (activeErrors.length) return;
                    this.sessionStorage.setItem('formId2', this.secondFormId);
                    this.sessionStorage.setItem('ScrollToForm2', 'true');
                    this.sessionStorage.setItem('showReceipt', 'true');
                }, 50)
            });
        }
    }
}
    