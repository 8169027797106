const axios = require('axios').default;
export default class Insights {
  constructor(element) {
    this.el = element
    this.pageId = document.getElementById("hdId").value;
    this.ctaText = this.el.dataset.cta_text;
    this.currentPage = 0;
    this.insightsPerPage = 6;
    this.insightsContainer = document.getElementById("insights");
    this.moreInsightsBtn = document.getElementById("more-insights-btn");
    this.tag = "";
    this.rootUrl = "https://" + window.location.host;
    this.getTagsUrl = this.rootUrl + "/umbraco/api/Insights/GetTags"
    this.getInsightsUrl = this.rootUrl + "/umbraco/api/Insights/GetInsightsByTag";
    this.tagListWrapper = document.querySelector('#tags');
    this.insightsInfo = document.getElementById("insights-info");
    this.init()
  }


  filterByTags(value) {
    this.getInsights(value.dataset.name, 0, this.insightsPerPage);
  };

  async init() {


    await this.getTagList().then(() => {
      document.querySelectorAll(".tag").forEach(tag => {
        tag.addEventListener("click", async (e) => {
          let tag = e.target.innerText;
          let path = window.location.href.split('?')[0];
          if (this.tag == tag) {
            tag = "";
            history.replaceState("", null, path);
          }
          else {
            history.replaceState("", null, path + "?tags=" + tag);
          }
          this.tag = tag;
          this.currentPage = 0;
          await this.getInsights(this.tag, 0, this.insightsPerPage);
        })
      });
    });

    this.moreInsightsBtn.addEventListener("click", async (e) => {
      await this.getInsights(this.tag, ++this.currentPage, this.insightsPerPage);
    });

    const params = new URLSearchParams(window.location.search)

    if (params.has('tags')) {
      this.tag = params.get("tags");
    }

    await this.getInsights(this.tag, 0, this.insightsPerPage);

  }


  async getTagList() {
    await axios.post(this.getTagsUrl, { guid: this.pageId }).then(res => {
      this.showTags(res.data)
    }).catch(e => console.log(e))
  }

  async getInsights(tag, page, size) {

    await axios.post(this.getInsightsUrl, {
      "overviewId": this.pageId,
      "pageSize": size,
      "page": page,
      "tag": tag
    })
      .then(res => {
        this.showInsights(res.data, page > 0 ? true : false);
      });

  }

  showTags(tags) {

    let tagList = document.createElement("ul");

    tags.forEach(element => {
      let tag = document.createElement("li");
      tag.classList.add("tag");
      tag.innerText = element;
      tag.dataset.name = element;

      tag.addEventListener("click", async (e) => {

        if (tag.classList.contains("active")) {
          tag.classList.remove("active");
        }
        else {
          tag.classList.add("active");
        }

        document.querySelectorAll(".tag").forEach(tagEle => {
          if (tagEle != tag) {
            tagEle.classList.remove("active");
          }
        });



        let tagText = e.target.innerText;
        let path = window.location.href.split('?')[0];
        if (this.tagText == tagText) {
          tagText = "";
          history.replaceState("", null, path);
        }
        else {
          history.replaceState("", null, path + "?tagTexts=" + tagText);
        }
        this.tagText = tagText;
        this.currentPage = 0;
        await this.getInsights(this.tagText, 0, this.insightsPerPage);
      })
      tagList.appendChild(tag);
    });

    this.tagListWrapper.appendChild(tagList);

  }




  showInsights(data, appendNew = false) {
    console.log(data);
    if (data.totalPages <= this.currentPage + 1) {
      this.moreInsightsBtn.disabled = true;
    }
    else {
      this.moreInsightsBtn.disabled = false;
    }

    if (!appendNew) {
      this.clearInsights();
    }

    data.results.forEach(insight => {
      this.insightsContainer.insertAdjacentHTML("beforeend", this.createInsightElement(insight));
    });

    let allCurrentlyDisplayed = ((data.page) * 6) + data.results.length;

    // Update insights info over "load more" button
    this.insightsInfo.innerText = "Showing 1-" + allCurrentlyDisplayed + "/" + data.totalResults;
  }

  clearInsights() {
    this.insightsContainer.innerHTML = "";
  }

  // TODO: Create function that uses a template string to create the element
  createInsightElement(insight) {
    console.log("insight", insight);
    let imageDesktop = document.createElement("img");
    const insightElement = `
      <a href="${insight.link}">
        <div class="insight">
          <img src="${insight.imageDesktop}" alt="">
          <div class="insight__tags">
          ${insight.tags.map(tag => `<span class="tag-capsule">${tag}</span>`).join('')}
          </div>
          <div class="insight-content">
            <div class="h4 insight__title">${insight.title}</div>
          </div>
        </div>
        <div class="insight__desc">
          <div class="insight__summary">${insight.description}</div>
          <button class="insight__link">${this.ctaText}
            <svg class="svg-arrow">
              <use xlink:href="#svg-arrow"></use>
            </svg>
          </button>
        </div>
      </a>
    `

    // let a = document.createElement("a");
    // a.href = insight.link;

    // let div = document.createElement("div");
    // div.classList.add("insight");

    // let title = document.createElement("h4")
    // title.className = "insight__title";
    // title.innerText = insight.title;

    // let description = document.createElement("p");
    // description.className = "insight__desc";
    // description.innerText = insight.description;

    // let imageDesktop = document.createElement("img");
    // imageDesktop.src = insight.imageDesktop;

    // let tags = document.createElement("div");
    // tags.className = "insight__tags";
    // tags.innerText = insight.tags.join(" ");

    // let link = document.createElement("a");
    // const linkText = link.innerText = "Read the article";

   
    // link.className = "insight__link";
    // link.innerText = linkText;
    // link.href = insight.link;



    // a.appendChild(div);
    // div.appendChild(imageDesktop);
    // div.appendChild(tags);
    // div.appendChild(title);
    // div.appendChild(description);
    // div.appendChild(link);

    // link.innerHTML += 
    // `<svg class="svg-arrow">
    //     <use xlink:href="#svg-arrow"></use>
    // </svg>`


    return insightElement;
  }
}
